import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Autoplay, Pagination } from "swiper/modules";

import h1 from "../../img/image 6.jpg";
import h2 from "../../img/image 16.jpg";
import h3 from "../../img/image 26.jpg";
import h4 from "../../img/slide1.png";
import h5 from "../../img/2.jpg";
import h6 from "../../img/image 31.jpg";
import h7 from "../../img/image 32.jpg";

import "./home.css";

function SliderEffect() {
  return (
      <div className="home2">
        <div className="home2-des-text">
        <h2 className="schedule-heading">Timing</h2>
        <p className="day">Monday to Friday:</p>
        <p className="time">6:30 am - 1:30 pm</p>
        <p className="time">5:00 pm - 9:00 pm</p>
        <p className="day">Saturday and Sunday:</p>
        <p className="time">5:00 am - 9:00 pm</p>
        </div>
        <Swiper
          id="effect-swiper"
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[EffectCoverflow, Autoplay, Pagination]}
        >
          <SwiperSlide>
            <img
              src={h1}
              alt="salon img"
              loading="lazy"
              effect="blur"
              height="100%"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={h2}
              alt="salon img"
              loading="lazy"
              effect="blur"
              height="100%"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={h3}
              alt="salon img"
              loading="lazy"
              effect="blur"
              height="100%"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={h4}
              alt="salon img"
              loading="lazy"
              effect="blur"
              height="100%"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={h5}
              alt="salon img"
              loading="lazy"
              effect="blur"
              height="100%"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={h6}
              alt="salon img"
              loading="lazy"
              effect="blur"
              height="100%"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={h7}
              alt="salon img"
              loading="lazy"
              effect="blur"
              height="100%"
            />
          </SwiperSlide>
        </Swiper>
      </div>
  );
}

export default SliderEffect;
