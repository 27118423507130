import "./App.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Articles from "./pages/Articles/Articles";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Scroll from "./components/Scroll";
import ImageGallery from "./pages/Gallery/Gallery";
import ContactUs from "./pages/Contact/Contact";
import History from "./pages/History/History";
import Card from "./pages/History/card/Card";
import History2 from "./pages/History/history2";

function App() {
  const Layout = () => {
    return (
      <div className="app">
        <ScrollToTop/>
        <Scroll/>
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/news-and-articles",
          element: <Articles />,
        },
        {
          path: "/gallery",
          element: <ImageGallery />,
        },
        {
          path: "/history",
          element: <Card/>,
        },
        {
          path: "/history1",
          element: <History/>
        },
        {
          path: "/history2",
          element: <History2/>
        },
        {
          path: "/contact-us",
          element: <ContactUs/>,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />
}

export default App;
