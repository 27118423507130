import React from "react";
import { Link } from "react-router-dom";
import "./card.css";
import img1 from "../../../img/2.jpg";
import img2 from "../../../img/2.jpg";

const Card = () => {
  return (
    <div className="back">
      <div className="title">
        <h1>திண்டுக்கல் ஸ்ரீமத் ஓத சுவாமிகள்</h1>
        <h1>வரலாறு</h1>
      </div>
      <div className="card-container">
        {/* First Card */}
        <div className="card">
          <div className="card-image">
          <img src={img1} alt="siddhar" />
          </div>
          <div className="card-content">
            <h2 className="card-title">
              திண்டுக்கல் ஸ்ரீமத் ஓத சுவாமிகள் - பகுதி 1
            </h2>
            
            <Link to="/history1">
              <button className="card-button">Read More</button>
            </Link>
          </div>
        </div>

        {/* Second Card */}
        <div className="card">
          <div className="card-image">
          <img src={img2} alt="siddhar" />
          </div>
          <div className="card-content">
            <h2 className="card-title">
              திண்டுக்கல் ஸ்ரீமத் ஓத சுவாமிகள் - பகுதி 2
            </h2>
          
            <Link to="/history2">
              <button className="card-button">Read More</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
