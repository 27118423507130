// // ContactForm.js
// import React from 'react';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';

// function ContactForm() {
//   return (
//     <div>
//       <form>
//         <TextField fullWidth label="Name" variant="outlined" margin="normal" required />
//         <TextField fullWidth label="Email" variant="outlined" margin="normal" required />
//         <TextField
//           fullWidth
//           label="Message"
//           variant="outlined"
//           margin="normal"
//           multiline
//           rows={7}
          
//           required
//         />
//         <Button type="submit" variant="contained" color="primary">
//           Submit
//         </Button>
//       </form>
//     </div>
//   );
// }

// export default ContactForm;


// ContactInfo.js
// ContactForm.js
import React, { useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';
import './ContactForm.css';

const ContactForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mzwagwr', 'template_gunanvp', form.current, '0kANSz8hN_1ucS5Gz')
      .then((result) => {
        setAlertMessage('Email sent successfully!');
        setAlertColor('green');
        console.log(result.text);
      })
      .catch((error) => {
        setAlertMessage('Error sending email. Please try again later.');
        setAlertColor('red');
        console.log(error.text);
      })
      .finally(() => {
       
        setFormData({
          user_name: '',
          user_email: '',
          message: '',
        });
      });
  };

  return (
    <div className="contact-form-container">
      <h1>Feedback</h1>
      <form ref={form} onSubmit={sendEmail}>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          margin="normal"
          required
          name="user_name"
          value={formData.user_name}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          required
          name="user_email"
          value={formData.user_email}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          label="Message"
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          required
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
      {alertMessage && (
        <div style={{ color: alertColor, marginTop: '10px' }}>
          {alertMessage}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
