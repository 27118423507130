import React from "react";
import logo from "../../img/siddhar.png";
import { MdLocationPin } from "react-icons/md";
import "./footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-top-container">
        <div className="footer-logo-container">
          <img src={logo} alt="Logo" className="footer-logo" />
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontWeight: '500',
              fontSize: '20px'
            }}
          >
            <span>Om Srimath</span>
            <span>OthaSwamigal Adhistanam.</span>
          </span>
        </div>

        <div className="about-us">
          <h2>About Us</h2>
          <p>
           
          </p>
          <address style={{ marginTop: "20px" }}>
            <MdLocationPin />
            Om Srimath OthaSwamigal Adhistanam
            Muthalagupatty, Dindigul, 
            Tamil Nadu 624001.
          </address>
        </div>

        <div className="footer-timing">
          <h2 className="schedule-heading">Timing</h2>
          <p className="day">Monday to Friday:</p>
          <p className="time">6:30 am - 1:30 pm</p>
          <p className="time">5:00 pm - 9:00 pm</p>
          <p className="day">Saturday and Sunday:</p>
          <p className="time">5:00 am - 9:00 pm</p>
        </div>
      </div>
      <hr className="footer-hr"/>
      <div className="footer-bottom-container">
        <div className="copyright">
          &copy; 2023 Your Company Name. All Rights Reserved.
        </div>
        <div className="developed-by">
          Developed by Your Name
        </div>
      </div>
    </div>
  );
}

export default Footer;
