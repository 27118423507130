import React from "react";

import "./banner.css";

function Banner({ imgSrc }) {
  return (
      <div className="banner-text">
        <h1>News & Articles</h1>
      </div>
  );
}

export default Banner;
