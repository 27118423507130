// GoogleMap.js
import React from 'react';
import './ContactForm.css';

function GoogleMap() {
  return (
    <div className="google-map">
       
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15698.937213180734!2d77.9627341!3d10.3631167!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00ab50c2e0b025%3A0x2560bc80d4e68768!2sOm%20Srimath%20Othaswamigal%20Adhistanam.!5e0!3m2!1sen!2sin!4v1695191643517!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
       
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default GoogleMap;
