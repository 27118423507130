import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";

import slide1 from "../../img/slide1.png";
import slide2 from "../../img/slide2.png";
import slide3 from "../../img/slide3.png";

import "./home.css";

function Hero() {
  return (
    <>
      <Swiper
        id="swiper-replace"
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
      >
        <SwiperSlide>
          <img loading="lazy" src={slide1} alt="siddhar" />
        </SwiperSlide>
        <SwiperSlide>
          <img loading="lazy" src={slide2} alt="siddhar" />
        </SwiperSlide>
        <SwiperSlide>
          <img loading="lazy" src={slide3} alt="siddhar" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Hero;
