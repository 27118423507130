import React, { useState } from "react";
import "./gallery.css";
import CloseIcon from "@mui/icons-material/Close";

import Img1 from "../../img/image 1.jpg";
import Img2 from "../../img/image 2.jpg";
import Img3 from "../../img/image 3.jpg";
import Img4 from "../../img/image 4.jpg";
import Img5 from "../../img/image 5.jpg";
import Img6 from "../../img/image 6.jpg";
import Img7 from "../../img/image 7.jpg";
import Img8 from "../../img/images 8.jpg";
import Img9 from "../../img/image 9.jpg";
import Img10 from "../../img/image 10.jpg";
import Img11 from "../../img/image 11.jpg";
import Img12 from "../../img/image 12.jpg";
import Img13 from "../../img/image 13.jpg";
import Img14 from "../../img/image 14.jpg";
import Img15 from "../../img/image 15.jpg";
import Img16 from "../../img/image 16.jpg";
import Img17 from "../../img/image 17.jpg";
import Img18 from "../../img/image 18.jpg";
import Img19 from "../../img/image 19.jpg";
import Img20 from "../../img/image 20.jpg";
import Img21 from "../../img/image 21.jpg";
import Img22 from "../../img/image 22.jpg";
import Img23 from "../../img/image 23.jpg";
import Img24 from "../../img/image 24.jpg";
import Img25 from "../../img/image 25.jpg";
import Img26 from "../../img/image 26.jpg";
import Img27 from "../../img/image 27.jpg";
import Img28 from "../../img/image 28.jpg";
import Img29 from "../../img/image 29.jpg";
import Img30 from "../../img/image 30.jpg";
import Img31 from "../../img/image 31.jpg";
import Img32 from "../../img/image 32.jpg";
const Gallery = () => {
  let data = [
    {
      id: 1,
      imgSrc: Img1,
    },
    {
      id: 2,
      imgSrc: Img2,
    },
    {
      id: 3,
      imgSrc: Img3,
    },
    {
      id: 4,
      imgSrc: Img4,
    },
    {
      id: 5,
      imgSrc: Img5,
    },
    {
      id: 6,
      imgSrc: Img6,
    },
    {
      id: 7,
      imgSrc: Img7,
    },
    {
      id: 8,
      imgSrc: Img8,
    },
    {
      id: 9,
      imgSrc: Img9,
    },
    {
      id: 10,
      imgSrc: Img10,
    },
    {
      id: 11,
      imgSrc: Img11,
    },
    {
      id: 12,
      imgSrc: Img12,
    },
    {
      id: 13,
      imgSrc: Img13,
    },
    {
      id: 14,
      imgSrc: Img14,
    },
    {
      id: 15,
      imgSrc: Img15,
    },
    {
      id: 16,
      imgSrc: Img16,
    },
    {
      id: 17,
      imgSrc: Img17,
    },
    {
      id: 18,
      imgSrc: Img18,
    },
    {
      id: 19,
      imgSrc: Img19,
    },
    {
      id: 20,
      imgSrc: Img20,
    },
    {
      id: 21,
      imgSrc: Img21,
    },
    {
      id: 22,
      imgSrc: Img22,
    },
    {
      id: 23,
      imgSrc: Img23,
    },
    {
      id: 24,
      imgSrc: Img24,
    },
    {
      id: 25,
      imgSrc: Img25,
    },
    {
      id: 26,
      imgSrc: Img26,
    },
    {
      id: 27,
      imgSrc: Img27,
    },
    {
      id: 28,
      imgSrc: Img28,
    },
    {
      id: 29,
      imgSrc: Img29,
    },
    {
      id: 30,
      imgSrc: Img30,
    },
    {
      id: 31,
      imgSrc: Img31,
    },
    {
      id: 32,
      imgSrc: Img32,
    },
  ];
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <div style={{backgroundColor: '#f7f7f7', paddingTop: '20px'}}>
      <h1 className="gallery-heading">Gallery</h1>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} alt="om srimath othaswamigal adhistanam" />
        <CloseIcon onClick={() => setModel(false)} />
      </div>
      <div className="gallery">
        {data.map((item, index) => {
          return (
            <div
              className="pics"
              key={item.id}
              onClick={() => getImg(item.imgSrc)}
            >
              <img
                src={item.imgSrc}
                alt={`siddhar ${item.id}`}
                style={{ width: "100%" }}
                loading="lazy"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
