// import React from 'react';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import ContactInfo from './ContactInfo';
// import ContactForm from './ContactForm';

// function ContactUs() {
//   return (
//     <Container>
//       <Typography variant="h4" gutterBottom sx={{ borderBottom: '2px solid #007bff', paddingBottom: 2, marginBottom: 4 }}>
//         Contact Us
//       </Typography>
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={6}>
//           <Paper elevation={3} sx={{ padding: 3 }}>
//             <div className="contact-info">
//               <ContactInfo />
//             </div>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Paper elevation={3} sx={{ padding: 3 }}>
//             <div className="contact-form">
//               <ContactForm />
//             </div>
//           </Paper>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// }

// export default ContactUs;

// ContactUs.js
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";
import "./contact.css"; // Import the CSS file
import GoogleMap from "./Googlemap";

function ContactUs() {
  return (
    <div className="map">
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              borderBottom: "2px solid #007bff",
              paddingBottom: 2,
              marginBottom: 4,
              fontWeight: "bold",
              fontSize: "3em",
              paddingTop: 2,
              backgroundImage: "url(../../img/shive-home-bg.png)",
            }}
          >
            Contact Us
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className="contact-divs">
              <ContactInfo />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className="contact-divs">
              <ContactForm />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <GoogleMap />
    </div>
  );
}

export default ContactUs;
