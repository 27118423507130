import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";

import "./scrollToTop.css";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {showTopBtn && (
        <div className="icon-position" onClick={goToTop}>
          <div className="icon-style">
            <div className="border"></div>
            <FaAngleUp className="icon" size={25}/>
          </div>
        </div>
      )}
    </div>
  );
};
export default ScrollToTop;
