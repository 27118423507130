// // ContactInfo.js
// import React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Typography from '@mui/material/Typography';

// function ContactInfo() {
//   return (
//     <div>
//       <Avatar alt="Contact Us" src="/contact-us.jpg" sx={{ width: 100, height: 100, margin: '0 auto' }} />
//       <Typography variant="h6" gutterBottom sx={{ marginTop: 2, fontWeight: 'bold' }}>
//         Contact Us
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         Admin Address: 123 Main St, City, Country
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         Email: example@example.com
//       </Typography>
//       <Typography variant="body1">
//         Contact Number: +1 123-456-7890
//       </Typography>
//     </div>
//   );
// }

// export default ContactInfo;

  // ContactInfo.js
import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import './ContactForm.css';
import pho from '../../img/siddhar.png';

function ContactInfo() {
  return (
    <div className="contact-info-container">
      <Avatar alt="Contact Us" src={pho} sx={{ width: 200, height: 210, margin: '0 auto' }} />
      {/* <Typography variant="h6" gutterBottom sx={{ marginTop: 2, fontWeight: 'bold' }}>
        Contact Us
      </Typography> */}
      <Typography variant="body1" gutterBottom sx={{ marginTop: 2}}>
       Address: 9X77+63X, Muthalagupatty, Dindigul, Tamil Nadu 624001.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Email: othaswamigal@gamil.com
      </Typography>
      <Typography variant="body1">
        Contact Number: 70104 31420
      </Typography>
    </div>
  );
}

export default ContactInfo;
