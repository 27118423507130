import React from "react";
import { Link } from "react-router-dom";
import Hero from "./Hero";
import video1 from "../../img/v2.mp4";
import slide1 from "../../img/slide1.png";
import img33 from "../../img/image 33.jpg";
import img34 from "../../img/image 34.jpg";
import img35 from "../../img/image 35.jpg";
import slide3 from "../../img/slide3.png";
import SliderEffect from "./SliderEffect";

import "./home.css";

function Home() {
  return (
    <>
      <Hero />
      <div className="first-des">
        <div className="des-text">
          <h2>ஸ்ரீ மத் ஓதசுவாமிகள் *காயத்ரி மந்திரம்*</h2>
          <br/><p>
        
ஓம் ஸ்ரீம் ஓதசுவாமினே வித்மஹே பத்மாசல வாசாய தீமஹி
தந்நோ ஷிம்வம் குருஹ் ப்ரசோதயாத்!
குருவே சரணம்
          </p>
        </div>
        <div className="image">
          <img loading="lazy" src={slide1} alt="home img" />
          <img loading="lazy" src={slide3} alt="home img" />
        </div>
      </div>
      <SliderEffect />
      <div className="home3">
        <h1 className="home3-head">News & Articles</h1>
        <ul className="home3-article-list">
          <li>
            <div className="home3-article-card">
              <img src={img33} alt="siddhar" />
              <div className="home3-card-content">
                <h2>
                  திண்டுக்கல் ஸ்ரீமத் ஓத சுவாமிகள் என்கிற சுப்பையா சுவாமிகள் 113
                  வது மகா குருபூஜை
                </h2>

                <Link
                  to="https://siddharbhoomi.com/%E0%AE%A4%E0%AE%BF%E0%AE%A3%E0%AF%8D%E0%AE%9F%E0%AF%81%E0%AE%95%E0%AF%8D%E0%AE%95%E0%AE%B2%E0%AF%8D-%E0%AE%B8%E0%AF%8D%E0%AE%B0%E0%AF%80%E0%AE%AE%E0%AE%A4%E0%AF%8D-%E0%AE%93%E0%AE%A4-%E0%AE%9A/"
                  className="btn"
                  target="_blank"
                >
                  Read More
                </Link>
              </div>
            </div>
          </li>
          <li>
            <div className="home3-article-card">
              <img src={img34} alt="siddhar" />
              <div className="home3-card-content">
                <h2>தன் சிஷ்யருக்கு, தனக்கு இணையாக இடமளித்த சித்தர்</h2>

                <Link
                  to="https://youtu.be/KZZ4yNoPiuc?si=T5Gin9rIlXnxMJ4A"
                  className="btn"
                  target="_blank"
                >
                  Watch it
                </Link>
              </div>
            </div>
          </li>
          <li>
            <div className="home3-article-card">
              <img src={img35} alt="siddhar" />
              <div className="home3-card-content">
                <h2>
                  History and Miracles of Sri Odha Guru Swamigal | Part 1 |
                  Nithilan Dhandapani
                </h2>

                <Link
                  to="https://youtu.be/IAW2uBN4B2c?si=uCV8YIVG5obSNyzA"
                  className="btn"
                  target="_blank"
                >
                  Watch it
                </Link>
              </div>
            </div>
          </li>
        </ul>
        <Link to="/news-and-articles" className="view-all">
          <button className="view-all-button">View All</button>
        </Link>
      </div>
      <video
        controls
        controlsList="nodownload"
        autoPlay
        muted
        loading="lazy"
        className="video-cont"
      >
        <source src={video1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
}

export default Home;
