import React from "react";
import { Link } from "react-router-dom";

import articleBg from "../../img/image 2.jpg";
import slide1 from "../../img/image 2.jpg";
import img1 from "../../img/2.jpg";
import img33 from "../../img/image 33.jpg";
import img34 from "../../img/image 34.jpg";
import img35 from "../../img/image 35.jpg";
import "./articles.css";
import Banner from "../../components/Banner/Banner";

function Articles() {
  return (
    <>
      <Banner imgSrc={articleBg} />

      <div className="articles-container">
          <ul className="home3-article-list">
            <li>
              <div className="home3-article-card">
              <img src={img33} alt="siddhar" />
                <div className="home3-card-content">
                  <h2>
                    திண்டுக்கல் ஸ்ரீமத் ஓத சுவாமிகள் என்கிற சுப்பையா சுவாமிகள்
                    113 வது மகா குருபூஜை
                  </h2>

                  <Link
                    to="https://siddharbhoomi.com/%E0%AE%A4%E0%AE%BF%E0%AE%A3%E0%AF%8D%E0%AE%9F%E0%AF%81%E0%AE%95%E0%AF%8D%E0%AE%95%E0%AE%B2%E0%AF%8D-%E0%AE%B8%E0%AF%8D%E0%AE%B0%E0%AF%80%E0%AE%AE%E0%AE%A4%E0%AF%8D-%E0%AE%93%E0%AE%A4-%E0%AE%9A/"
                    className="btn"
                    target="_blank"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="home3-article-card">
                <div className="second">
                  <img src={img1} alt="siddhar" />
                </div>
                <div className="home3-card-content">
                  <h2>
                    தினமலர்-ஸ்ரீமத் ஓத சுவாமிகள் என்று பக்தர்களால் அழைக்கப்படும்
                    இவரை...
                  </h2>

                  <Link
                    to="https://temple.dinamalar.com/news_detail.php?id=36653"
                    className="btn"
                    target="_blank"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="home3-article-card">
              <img src={img34} alt="siddhar" />
                <div className="home3-card-content">
                  <h2>தன் சிஷ்யருக்கு, தனக்கு இணையாக இடமளித்த சித்தர்</h2>

                  <Link
                    to="https://youtu.be/KZZ4yNoPiuc?si=T5Gin9rIlXnxMJ4A"
                    className="btn"
                    target="_blank"
                  >
                    Watch it
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="home3-article-card">
              <img src={img35} alt="siddhar" />
                <div className="home3-card-content">
                  <h2>
                    History and Miracles of Sri Odha Guru Swamigal | Part 1 |
                    Nithilan Dhandapani
                  </h2>

                  <Link
                    to="https://youtu.be/IAW2uBN4B2c?si=uCV8YIVG5obSNyzA"
                    className="btn"
                    target="_blank"
                  >
                    Watch it
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="home3-article-card">
                <img src={slide1} alt="siddhar" />
                <div className="home3-card-content">
                  <h2>
                    Astonishing Miracles of Sri Odha Guru Swamigal | Part 2 |
                    Nithilan Dhandapani
                  </h2>

                  <Link
                    to="https://youtu.be/9JSBJKxnA-E?si=trWJPB0LuR-IcDy8"
                    className="btn"
                    target="_blank"
                  >
                    Watch it
                  </Link>
                </div>
              </div>
            </li>
          </ul>
      </div>
    </>
  );
}

export default Articles;
