import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../img/siddhar.png";
import "./navbar.css";

function Navbar() {
  const [active, setActive] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const isActive = () => {
    window.scrollY > 0 ? setActive(true) : setActive(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", isActive);

    return () => {
      window.removeEventListener("scroll", isActive);
    };
  }, []);

  return (
    <div className={active ? "navbar nav-active" : "navbar"}>
      <div className="container">
        <div>
          <Link to="/" className="link logo">
            <img src={logo} alt="siddhar" className="temple-img" />
            <span style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <span className="text">Om Srimath</span>
              <span className="text">OthaSwamigal Adhistanam.</span>
            </span>
          </Link>
        </div>
        <ul className={mobileView ? 'links mobile-view' : 'links'}>
          <NavLink to="/" className="link" onClick={() => setMobileView(false)}>
            <li>Home</li>
          </NavLink>
          <NavLink to="/gallery" className="link" onClick={() => setMobileView(false)}>
            <li>Gallery</li>
          </NavLink>
          <NavLink to="/history" className="link" onClick={() => setMobileView(false)}>
            <li>History</li>
          </NavLink>
          <NavLink to="/news-and-articles" className="link" onClick={() => setMobileView(false)}>
            <li>News & Articles</li>
          </NavLink>
          <NavLink to="contact-us" className="link" onClick={() => setMobileView(false)}>
            <li>Contact</li>
          </NavLink>
        </ul>
        <div className="mobile" onClick={() => setMobileView(!mobileView)}>
          {mobileView ? <FaTimes size={25}/> : <FaBars size={25}/>}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
